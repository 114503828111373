<template>
  <div>
    <div class="container small">
      <h1 class="page-headline">{{ product.productName }}</h1>
      <progressIndicator :step="product.step" :steps="product.steps" />
    </div>
    <template v-if="product.step == 1">
      <div class="container small">
        <div class="box">
          <FormStep
            :fields="product.fields"
            :product="product"
            :step="product.step"
            v-on:submitForm="submitStep1"
            v-on:formFunction="formFunction"
          />
        </div>

        <!-- Fejlbesked -->
        <message
          v-show="error.step1"
          :message="error.step1"
          type="warning"
          v-on:formFunction="formFunction"
        />
      </div>
    </template>
    <template v-else-if="product.step == 2">
      <form id="step2" v-on:submit.prevent="submitStep2">
        <div class="container small">
          <div class="box">
            <!-- Felter på trin 2 -->
            <template v-for="(field, index) in product.fields">
              <FormInput
                v-if="field.step == 2"
                :data="field"
                :product="product"
                :key="index"
                v-on:formFunction="formFunction"
              />
            </template>
          </div>

          <!-- Fejlbesked -->
          <message
            v-show="error.price"
            :message="error.price"
            type="warning"
            v-on:formFunction="formFunction"
          />
        </div>

        <!-- Pakkekomponent -->
        <packages
          altTitle="Ulykkesforsikringen dækker"
          altLinkText="Læs mere om vores ulykkesforsikringer"
          :packages="product.packages"
          :packageLink="product.packageLink"
          :requirementsMet="allRequirementsMet"
          v-on:packageChoice="choosePackage"
        />

        <!-- Extra packages -->
        <coverages
          v-model="product.coverages"
          :requirementsMet="allRequirementsMet"
          v-on:packageChoice="choosePackage"
        >
          <template v-slot:key-100="props">
            <div v-show="props.coverage.base.chosen" style="padding: 20px;">
              <FormInput
                :data="product.fields.deathSumInsured"
                :product="product"
              />
            </div>
          </template>
        </coverages>

        <div class="container small">
          <!-- Infobesked -->
          <message
            v-show="info.step2"
            :message="info.step2"
            type="info"
            v-on:formFunction="formFunction"
          />
        </div>
      </form>
    </template>

    <!-- Buttons on bottom -->
    <div class="container small">
      <ProgressButtons
        :step="product.step"
        :steps="product.steps"
        :existingPolicy="existingPolicy"
        @changeStep="changeStep"
      />
    </div>

    <!-- Product footer -->
    <product-footer
      :productName="product.productName"
      :productId="product.productId"
      objectCode="FOR"
      :productLink="product.productLink"
      v-on:contactFromFooter="messageBox"
      alt-title="Mere om vores ulykkesforsikring"
    >
      Vores ulykkesforsikring sikrer dig en økonomisk erstatning, hvis du kommer ud for en ulykke og får et varigt mén på 5 % eller derover. Opstår der behandlingsudgifter til fysioterapeut, akupunktør eller lignende som følge af ulykken, kan du få dækket disse. Det samme gør sig gældende for tandlægeudgifter, hvis der sker en tandskade i forbindelse med ulykken. Forsikringen dækker også sportsulykker og farlig sport.
    </product-footer>
  </div>
</template>
<script>
import U06 from "@/assets/json/U06_adult.json";
import FormInput from "@/components/FormInput.vue";
import progressIndicator from "@/components/progress-indicator.vue";
import ProgressButtons from "@/components/ProgressButtons.vue";
import message from "@/components/message.vue";
import productFooter from "@/components/product-footer.vue";
import packages from "@/components/packages.vue";
import FormStep from "@/components/FormStep.vue";
import commonProduct from "@/mixins/commonProduct.js";
import validateData from "@/mixins/validateData.js";
import debounce from "lodash/debounce";
import coverages from "@/components/coverages.vue";

export default {
  name: "U06_Adult",
  components: {
    FormInput,
    progressIndicator,
    ProgressButtons,
    message,
    productFooter,
    packages,
    FormStep,
    coverages,
  },
  props: {
    policy: {
      type: Object,
      default: null,
    },
  },
  mixins: [commonProduct, validateData],
  data() {
    return {
      productId: "U06",
      objectCode: "FOR",
      product: this.copy(U06),
      temporaryProduct: this.copy(this.$store.state.temporaryProduct),
    };
  },
  created() {
    /*
     * If you edit and existing offer copy the policy here, or if you have temporary object
     */
    if (this.policy) {
      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      this.product = this.copy(this.policy.value);
      //this.existingPolicy = true;
      //this.existingPolicyKey = this.policy.key;
    } else if (
      !this.$route.query.input &&
      this.temporaryProduct &&
      this.temporaryProduct.productId == this.productId &&
      this.temporaryProduct.objectCode == this.objectCode
    ) {
      this.product = this.copy(this.temporaryProduct);
    }
  },
  mounted() {
    // Function if input has come from website
    if (this.$route.query.input) {
      // Get query and delete input
      let query = this.copy(this.$route.query);
      query.input = undefined;

      // Push on without input
      this.$router
        .push({ name: "ulykkesforsikring-voksen", query: query })
        .catch(() => {});
    }
  },
  computed: {
    /*
     * Check requirements
     */
    allRequirementsMet() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required) {
          if (!value.value || value.error) {
            this.cerror("Not all requirements are met", value);
            return false;
          }
        }
      }
      return true;
    },
  },
  watch: {
    "product.fields.deathSumInsured.value": {
      handler() {
        this.scrollTo("packages");
      }
    },
    "product.fields": {
      handler: function () {
        this.calculateEveryPrice();
      },
      deep: true,
    },
    "product.coverages": {
      handler: function () {
        // Did we remove death sum?
        if (!this.product.coverages.death.chosen && this.product.fields.deathSumInsured.value !== null) {
          this.product.fields.deathSumInsured.value = null;
        }

        this.calculateEveryPrice();
      },
      deep: true,
    }
  },
  methods: {
    /*
     * Reset zipcode
     */
    resetZipcode() {
      this.product.fields.zipCode.value = null;
    },
    /*
     * Set zipcode
     */
    setZipcode(value) {
      this.product.fields.zipCode.value = value.postnummer.nr;
    },
    /*
     * Event when you choose a package
     */
    calculateEveryPrice: debounce(function () {
      let self = this;

      // Show info on step 2 for specific age range
      const age = this.$options.filters.cprToAge(this.product.fields.cprCvr.value);

      this.info.step2 = age >= 18 && age <= 29
          ? {
              headline: "Vi ønsker at give unge mennesker den bedste start på voksenlivet",
              text: "Fordi du er mellem 18 og 29 år, indeholder ulykkesforsikringen en unik Ungdomstandforsikring uden ekstra omkostninger. Dermed er du ikke kun dækket ved tandskader, som er opstået i forbindelse med en ulykke, men også ved behandlingsbehov i langt de fleste tilfælde – og det uanset årsagen. Ungdomstandforsikring dækker op til 20.000 kr. pr. år med en selvrisiko på 1.995 kr.",
              button: null,
            }
          : null;

      // Update temporary product if product is not existing
      if (!self.existingPolicy) {
        self.$store.commit("temporaryProduct", self.product);
      }

      // If all requirements are not met, break from function
      if (!self.allRequirementsMet) return;

      // Remove error
      self.error.price = null;

      // Get submit data without coverages
      let submitData = self.parseSubmitData(self.product);

      // Console log if in dev, test or beta environment
      this.clog('data', submitData);

      // Create encrypted data
      let encryptedData = JSON.stringify(
        self.encrypt(JSON.stringify(submitData))
      );

      // Create api string
      let apiString = `/api/price`;

      self
        .axios({
          method: "post",
          url: apiString,
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
          data: encryptedData,
        })
        .then((response) => {
          // If weird error shows, run function again
          /*if (response.data.objects && response.data.objects.length < 3) {
            //self.calculateEveryPrice();
            return;
          }*/

          self.insertPrice(response.data);
        })
        .then(() => {
          // Show popup
          self.$store.commit("showPopup");

          // Update temporary product if product is not existing
          if (!self.existingPolicy) {
            self.$store.commit("temporaryProduct", self.product);
          }

          // If exiting policy, update existing policy
          if (self.existingPolicy) {
            self.updateExistingPolicy(self.product, self.existingPolicyKey);
          }
        })
        .catch(function () {
          // Show error
          self.error.price = {
            headline: "Vi kan desværre ikke beregne en pris online",
            text: "Det er desværre ikke muligt at beregne en pris online. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
            button: {
              text: "Kontakt mig",
              function: "messageBox",
            },
          };

          // Remove loading for packages
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(self.product.packages)) {
            value.loading = null;
          }
        });
    }, 250),
    /*
     * Submit step 1 in form
     */
    async submitStep1() {
      let self = this;

      // Set step 1 to loading
      self.product.steps[0].loading = true;

      // Reset validation error
      self.validationError = null;

      // Reset error message
      self.error[`step${self.product.step}`] = null;

      // Validate the data from step 1
      let validationResult = await self.validateData(
        self.product,
        self.product.step
      );

      // Mod 11 control
      const cprFld = this.product.fields.cprCvr;

      if (!this.checkModulus11(cprFld.value)) {
        cprFld.error = cprFld.validationMessage;

        // Set loading to false
        self.product.steps[0].loading = false;

        return;
      }

      // Test age
      const age = this.$options.filters.cprToAge(this.product.fields.cprCvr.value);
      this.product.fields.age.value = age;

      if (age < 18) {
        validationResult = {
          isValid: false,
          message: "Ud fra den oplyste alder kan vi desværre ikke beregne en pris online. Ønsker du at købe en ulykkesforsikring til dit barn, anbefaler vi, at du vælger en børneulykkesforsikring, der dækker, indtil dit barn fylder 18 år. Har du brug for personlig rådgivning, kan du udfylde vores kontaktformular. Du vil herefter blive kontaktet af vores kundeservice med et tilbud på dine forsikringer.",
        };
      }

      // Send customer along if valid, otherwise show error
      if (validationResult.isValid) {
        self.product.step += 1;
      } else {
        // Set validation error from response
        self.validationError = validationResult.message;

        // Set error message
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: validationResult.message,
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }

      // Set loading to false
      self.product.steps[0].loading = false;
    },
    /*
     * Submit step 2 in form
     */
    submitStep2() {
      // Verify selection
      if (this.product.coverages.death.chosen && this.product.fields.deathSumInsured.value === null) {
        this.product.fields.deathSumInsured.error = "Du skal vælge din forsikringssum";
        return;
      }

      // Check if policy is exitting or new
      if (this.existingPolicy) {
        this.updateExistingPolicy(this.product, this.existingPolicyKey);
      } else {
        this.$store.commit("addProduct", this.product);
      }

      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      // Route to shopping basket
      this.$router.push({ name: "Kurv" });
    },
    /*
     * Update existing policy
     */
    updateExistingPolicy(product, key) {
      this.$store.commit("updateProduct", {
        value: product,
        key: key,
      });
    },
  },
};
</script>
